import React, { useState } from 'react';

const TransportationOptions = ({ transportationOptions, shipmentId, showError, showSuccess }) => {
  const [nameFilter, setNameFilter] = useState('');
  const [solutionFilter, setSolutionFilter] = useState('');

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    return new Date(dateTimeString).toLocaleString();
  };

  const formatCurrency = (amount, currencyCode) => {
    if (!amount) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
    }).format(amount);
  };

  const filteredOptions = transportationOptions.filter(
    (option) =>
      option.carrier.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
      option.shipping_solution.toLowerCase().includes(solutionFilter.toLowerCase())
  );

  const totalOptions = transportationOptions.length; // Total number of transportation options

  return (
    <div className="card mt-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <div className="card-header bg-white d-flex justify-content-between align-items-center">
        <h3 className="card-title fs-6 mb-0">
          Transportation Options ({totalOptions}) {/* Display total options */}
        </h3>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by carrier name..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by shipping solution..."
              value={solutionFilter}
              onChange={(e) => setSolutionFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-hover table-borderless text-nowrap">
            <thead>
              <tr>
                <th>Carrier</th>
                <th>Carrier Code</th>
                <th>Shipping Mode</th>
                <th>Shipping Solution</th>
                <th>Quote Amount</th>
                <th>Quote Expiration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOptions.map((option) => (
                <tr key={option.id}>
                  <td>{option.carrier.name}</td>
                  <td>{option.carrier.alphaCode}</td>
                  <td>{option.shipping_mode}</td>
                  <td>{option.shipping_solution}</td>
                  <td>
                    {option.quote?.cost?.amount && (
                      <>
                        {formatCurrency(option.quote.cost.amount, option.quote.cost.currencyCode)}
                        {option.quote.cost.currencyCode}
                      </>
                    )}
                  </td>
                  <td>{formatDateTime(option.quote?.expiration)}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      data-toggle="modal"
                      data-target={`#transportDetails${option.id}`}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransportationOptions;
