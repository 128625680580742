import React, { useState, useEffect, useRef } from "react";
import { fetcher } from "../utlities/adapter";

const OperationStatus = ({ operationId, accountId }) => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasRedirected = useRef(false); // To prevent multiple redirects

  // Function to dynamically generate the plan path
  const generatePlanPath = (operationId) => {
    return `/${accountId}/inbound_shipment_plan_requests/${operationId}/view_plan`; // Update this based on your Rails routing conventions
  };

  const updateStatusInDb = async (operationId, operationStatus) => {
    try {
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

      const response = await fetch(
        `/api/${accountId}/inbound_operation_status/${operationId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify({ operation_status: operationStatus }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update status: ${response.statusText}`);
      }

      console.log(`Status ${operationStatus} successfully updated in the database.`);
    } catch (err) {
      console.error("Failed to update status in database:", err);
    }
  };

  const checkStatus = async () => {
    try {
      const response = await fetcher(
        `/${accountId}/inbound_operation_status/${operationId}`,
        "GET"
      );

      setStatus(response);

      // Update the database with the current status
      await updateStatusInDb(operationId, response.operationStatus);

      if (response.operationStatus === "IN_PROGRESS") {
        // Continue polling every 5 seconds
        setTimeout(() => checkStatus(), 5000);
      } else if (response.operationStatus === "SUCCESS" && !hasRedirected.current) {
      } else {
        setLoading(false); // Stop loading for other statuses
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountId && operationId) {
      checkStatus();
    }
  }, [accountId, operationId]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!status) {
    return <div>Loading operation status for {operationId}...</div>;
  }


  return (
    <div className="card my-4">
      <div className="card-body">
        <h5 className="card-title">Operation Status</h5>
        <p>
          <strong>Operation ID:</strong> {status.operationId}
        </p>
        <p>
          <strong>Status:</strong> {status.operationStatus}
        </p>
        <p>
          <strong>Operation Type:</strong> {status.operation}
        </p>

        {status.operationProblems && status.operationProblems.length > 0 && (
          <div>
            <h6>Problems:</h6>
            {status.operationProblems.map((problem, index) => (
              <div
                key={index}
                className={`alert alert-${
                  problem.severity === "ERROR" ? "danger" : "warning"
                }`}
              >
                <strong>{problem.code}</strong>: {problem.message}
                {problem.details && (
                  <div>
                    <small>{problem.details}</small>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {loading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OperationStatus;
