import React, { useState, useEffect } from 'react';
import BoxItem from './BoxItem';
import { fetcher } from "../../utlities/adapter";

const Box = ({ box, onBoxDeleted, onBoxUpdated, onBoxDuplicated, remainingItems }) => {
  const [loading, setLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    length: box.length || '',
    width: box.width || '',
    height: box.height || '',
    weight: box.weight || ''
  });
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateCount, setDuplicateCount] = useState(1);

  const handleDimensionChange = (e) => {
    setDimensions({
      ...dimensions,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateDimensions = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/admin/boxes/${box.id}`,
        'PATCH',
        { box: dimensions }
      );
      if (response.success) {
        onBoxUpdated(response.box);
        window.topAlert('Box updated successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error updating box', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const toggleDuplicateModal = () => {
    setShowDuplicateModal(!showDuplicateModal);
  };

  const handleDuplicateCountChange = (e) => {
    setDuplicateCount(parseInt(e.target.value, 10) || 1);
  };

  const handleDuplicateForm = async () => {
    setLoading(true);
    let allSuccessful = true; // Flag to track if all duplications are successful

    for (let i = 0; i < duplicateCount; i++) {
        const url = `/admin/boxes/${box.id}/duplicate?react=true`;
        try {
            const response = await fetcher(url, 'POST', {});
            if (response.success) {
                onBoxDuplicated(response.box);
                window.topAlert(`Box ${response.box.id} duplicated successfully`, 'success');
            } else {
                allSuccessful = false; // Mark as unsuccessful if duplication fails
                window.topAlert(response.message || 'Error duplicating box', 'danger');
                break; // Stop the loop if duplication fails
            }
        } catch (error) {
            allSuccessful = false; // Mark as unsuccessful if an error occurs
            window.topAlert('Error duplicating box', 'danger');
            break;
        }
    }

    setLoading(false);
    toggleDuplicateModal(); // Close the modal after duplications

    if (allSuccessful) {
        window.location.reload(); // Reload the page if all duplications were successful
    }
};


  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `/admin/boxes/${box.id}`,
        'DELETE'
      );
      if (response.success) {
        onBoxDeleted(box.id);
        window.topAlert('Box deleted successfully', 'success');
      }
    } catch (error) {
      window.topAlert('Error deleting box', 'danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Box #{box.id}</h5>
        <div>
          <button
            className="btn btn-sm btn-secondary me-2"
            onClick={toggleDuplicateModal}
          >
            <i className="fas fa-copy"></i>
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={handleDelete}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </div>

      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="length" className="form-label">Length</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Length"
              name="length"
              value={dimensions.length}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="width" className="form-label">Width</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Width"
              name="width"
              value={dimensions.width}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="height" className="form-label">Height</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Height"
              name="height"
              value={dimensions.height}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="weight" className="form-label">Weight</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Weight"
              name="weight"
              value={dimensions.weight}
              onChange={handleDimensionChange}
            />
          </div>
          <div className="col-md-1 d-flex align-items-end">
            <button
              className="btn btn-primary btn-sm mb-0"
              onClick={handleUpdateDimensions}
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="fas fa-check"></i>
              )}
            </button>
          </div>
        </div>

        <BoxItem
          boxId={box.id}
          items={box.items || []}
          remainingItems={remainingItems}
          onBoxUpdated={onBoxUpdated}
        />
      </div>

      {/* Modal for duplicating boxes */}
      {showDuplicateModal && (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Duplicate Box</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleDuplicateModal}
                ></button>
              </div>
              <div className="modal-body">
                <label>How many duplicates?</label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  value={duplicateCount}
                  onChange={handleDuplicateCountChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleDuplicateForm}
                  disabled={loading}
                >
                  {loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    'Duplicate'
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={toggleDuplicateModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Box;
